import CollectionsIcon from '@mui/icons-material/Collections';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface GalleryProps {
}

const Gallery: React.FC<GalleryProps> = ({ }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/full/${6}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative",
                // zIndex: 3,
                "&::before": {
                    background: "#0e0e0e",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    content: '""',
                    opacity: .4
                }
            }}
            pl={0}
            pr={0}
            width={"100%"}
            height={"100vh"}
            // marginLeft={"calc(50% - 50vw)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid
                pt={6}
                pb={6}
                zIndex={10}
                container
                justifyContent={"flex-start"}>
                <Container maxWidth="xl">
                    <Typography color={"#fff"} variant='h1'>{t("aboutUs.gallery.title")}</Typography>
                    <Box mt={4} maxWidth={600}>
                        {/* <Typography color={"#fff"} variant='h6'>{t("aboutUs.city.row1")}</Typography>
                        <Typography mt={2} color={"#fff"} variant='h6'>{t("aboutUs.city.row2")}</Typography> */}
                    </Box>
                    <Button
                        onClick={() => navigate("/gallery")}
                        sx={{
                            pt: 2,
                            pb: 2,
                            pl: 6,
                            pr: 6
                        }} variant='contained'>
                        <Box display={"flex"} flexDirection={"row"}>
                            <CollectionsIcon />
                            <Typography ml={1} fontWeight={700}>
                                {t("aboutUs.gallery.openGallery")}
                            </Typography>
                        </Box>
                    </Button>
                </Container>
            </Grid>
        </Box>
    )
}

export default Gallery;