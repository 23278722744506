import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomMap from './CustomMap';

interface GoogleMapsDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const GoogleMapsDialog: React.FC<GoogleMapsDialogProps> = ({ isOpen, onClose }) => {
    return (
        <Dialog onClose={onClose} open={isOpen} fullWidth maxWidth="lg"
            PaperProps={{
                sx: {
                    height: "100%"
                }
            }}
            sx={{
                margin: -1,
            }}>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{
                mt: 6,
                p: 0.5
            }}>
                <CustomMap />
            </DialogContent>
        </Dialog>
    )
}

export default GoogleMapsDialog;