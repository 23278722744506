import React from 'react';
import { Box, Typography } from '@mui/material';
import SocialMedia from './SocialMedia';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

interface SocialMediaListProps {
}

const SocialMediaList: React.FC<SocialMediaListProps> = ({ }) => {
    return (
        <Box display={"flex"} flexDirection={"row"} gap={1}>
            <SocialMedia link="https://www.youtube.com/@enduroremi861" icon={<YouTubeIcon sx={{ color: "#FF0000" }} />} />
            <SocialMedia link="https://www.instagram.com/petarenduro?igsh=NWswd3V1cGRkNzFx" icon={<InstagramIcon sx={{ color: "#F56040" }} />} />
            <SocialMedia link="https://www.facebook.com/profile.php?id=100055137862547" icon={<FacebookIcon sx={{ color: "#316FF6" }} />} />
        </Box>
    )
}

export default SocialMediaList;