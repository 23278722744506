import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box, ButtonBase, Fab, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface SocialMediaProps {
    link: string;
    icon: React.ReactNode;
}

const SocialMedia: React.FC<SocialMediaProps> = ({ link, icon }) => {
    const { t } = useTranslation();
    return (

        <Fab onClick={() => window.open(link)} sx={{bgcolor: "#fff"}}>
            {icon}
        </Fab>


        // <ButtonBase onClick={() => window.open(link)}>
        //     <Box
        //         sx={{
        //             boxShadow: "#ffffff0d 0px 0px 0px 1.2px",
        //             bgcolor: "#fff"
        //         }}
        //         p={1.5}
        //         borderRadius={5}
        //         display={"flex"}
        //         flexDirection={"column"}
        //         alignItems={"center"}
        //     >

        //     </Box>
        // </ButtonBase>
    )
}

export default SocialMedia;