
import React, { useEffect } from "react"
import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";

interface YoutubeItemProps {
    embed: string,
}

const YoutubeItem: React.FC<YoutubeItemProps> = ({ embed }) => {
    return (
        <Paper
            sx={(theme) => ({
                // mb: 5,
                width: "100%",
                // maxWidth: 480,
                height: 220,
                [theme.breakpoints.up("sm")]: {
                    height: 272
                },
                position: "relative",
                borderRadius: 1,
                boxShadow: (theme) => theme.shadows[5]
            })}>
            <iframe
                width="100%"
                height="100%"
                style={{borderRadius: 8}}
                src={`https://www.youtube.com/embed/${embed}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Enduro-Remi youtube"
            />
        </Paper>
    )
}

export default YoutubeItem;