import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExtraOffer from './ExtraOffer';
import IncludedBasic from './IncludedBasic';
import IncludedMotors from './IncludedMotors';
import Tour from '../../../../models/Tour';

interface TourItemDescriptionProps {
    tour: Tour
}

const TourItemDescription: React.FC<TourItemDescriptionProps> = ({ tour}) => {
    const { t } = useTranslation();
    return (
        <>
            <Divider sx={{ mt: 1, mb: 0.5 }} flexItem >
                <Typography variant='caption' color={"text.secondary"}>{t("tour.motors")}</Typography>
            </Divider>

            <IncludedMotors />

            <Divider sx={{ mt: 1.5, mb: 0.5 }} flexItem >
                <Typography variant='caption' color={"text.secondary"}>{t("tour.additionalOffer")}</Typography>
            </Divider>

            <ExtraOffer />
        </>
    )
}

export default TourItemDescription;