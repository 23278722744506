import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

interface TourIncludedItemProps {
    title: string,
    displayIcon?: boolean
}

const TourIncludedItem: React.FC<TourIncludedItemProps> = ({ title, displayIcon = true }) => {
    return (
        <Box mt={0.5} display={"flex"} flexDirection={"row"} alignItems={"center"}>
            {displayIcon && <CheckRoundedIcon color='primary' />}
            <Typography
                variant='subtitle2'
                ml={0.5} >
                {title}
            </Typography>
        </Box>
    )
}

export default TourIncludedItem;