import React from 'react';
import { useTranslation } from 'react-i18next';
import TourIncludedItem from './TourIncludedItem';
import Tour from '../../../../models/Tour';

interface IncludedBasicProps {
    tour: Tour
}

const IncludedBasic: React.FC<IncludedBasicProps> = ({ tour }) => {
    const { t } = useTranslation();
    return (
        <>
            {/* <TourIncludedItem title={`${tour.nightCount} ${t("tour.nights")}`} />
            <TourIncludedItem title={`${tour.rideDayCount} ${t("tour.daysRide")}`} /> */}
            <TourIncludedItem title={t("tour.accommodation")} />
            <TourIncludedItem title={t("tour.breakfast")} />
            <TourIncludedItem title={t("tour.guide")} />
        </>
    )
}

export default IncludedBasic;