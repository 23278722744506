import React from "react";
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';
import PromoItem from "./PromoItem";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface PromoSliderProps {

}

const PromoSlider = ({ }: PromoSliderProps) => {
    const { t } = useTranslation();
    const [activePromoItem, setActivePromotem] = React.useState(1);

    const handleOnPromoItemChange = (index: number) => {
        setActivePromotem(index);
    }

    return (
        <Box
            p={{ xs: 1, md: 2 }}
            width={"100vw"}
            marginLeft={"calc(50% - 50vw)"}>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={16}
                loop={true}
                // centeredSlides={true}
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text1")}
                        subTitle=""
                        imageName="slider3"
                        backgroundColor="#a28b7120" />
                </SwiperSlide>

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text2")}
                        subTitle=""
                        imageName="slider1"
                        backgroundColor="#526a2720" />
                </SwiperSlide>

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text3")}
                        subTitle=""
                        imageName="slider8"
                        backgroundColor="#524c2f20" />
                </SwiperSlide>

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text4")}
                        subTitle=""
                        imageName="slider2"
                        backgroundColor="#84953e20" />
                </SwiperSlide>

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text5")}
                        subTitle=""
                        imageName="slider6"
                        backgroundColor="#73653d20" />
                </SwiperSlide>

                <SwiperSlide>
                    <PromoItem
                        title={t("slider.text6")}
                        subTitle=""
                        imageName="slider7"
                        backgroundColor="#3c512420" />
                </SwiperSlide>
            </Swiper>
        </Box>
    )
}

export default PromoSlider;