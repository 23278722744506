import { Box, Button, ButtonGroupButtonContext, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneButton from '../components/common/PhoneButton';
import SocialMediaList from '../components/common/SocialMediaList';
import WhatsAppPhoneButton from '../components/common/WhatsAppPhoneButton';
import FooterAddress from './FooterAddress';
import CopyButton from '../components/common/CopyButton';

interface FooterProps {
}

const Footer: React.FC<FooterProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <Grid
            p={4}
            mt={{ xs: 4, md: 8 }}
            container
            bgcolor={"#121212"}
            width={"100%"}
            direction={"row"}
            justifyContent={{ xs: "flex-start", md: "center" }}
            alignItems={"start"}>
            <Box
                display={"grid"}
                justifyContent={"flex-start"}
                width={"auto"}
                gap={{ xs: 6, md: 14 }}
                sx={{
                    gridTemplateColumns: { xs: "repeat(1, 1fr) !important", sm: "repeat(1, 1fr) !important", md: "repeat(2, 1fr) !important", lg: "repeat(4, 1fr) !important" }
                }}>
                {/* <Box display={"flex"} flexDirection={"column"}>
                    <CardMedia
                        id="card-media"
                        component="img"
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
                        alt={"1"}
                        sx={(theme) => ({
                            // height: 180,
                            width: 140,
                            objectFit: "contain"
                            // borderRadius: 1,
                        })}
                    />
                </Box> */}

                <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                        color={(theme) => theme.palette.primary.light}
                        mb={1}
                        fontWeight={600}
                        sx={{
                            // textDecoration: "underline",
                            // textDecorationColor: (theme) => theme.palette.primary.light
                        }}>
                        {t("footer.followUs")}
                    </Typography>
                    <SocialMediaList />
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                        color={(theme) => theme.palette.primary.light}
                        mb={1}
                        fontWeight={600}
                        sx={{
                            // textDecoration: "underline",
                            // textDecorationColor: (theme) => theme.palette.primary.light
                        }}>
                        {t("footer.contact")}
                    </Typography>
                    {/* <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} alignItems={"flex-start"} gap={{xs: 4, md: 6}}> */}
                    <Box>
                        <Typography color={"#fff"} variant='subtitle2' fontWeight={600}>Petar Bagarić</Typography>
                        <Typography color={"#fff"} variant='subtitle2'>+387 63 284 294</Typography>
                        <Typography color={"#fff"} variant='subtitle2'>bagaricpetar2@gmail.com</Typography>
                        <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                            <PhoneButton phoneNumber='+387 63 284 294' />
                            <WhatsAppPhoneButton phoneNumber='38763284294' />
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <Typography color={"#fff"} variant='subtitle2' fontWeight={600}>Fonko Oreć</Typography>
                        <Typography color={"#fff"} variant='subtitle2'>+387 63 384 171</Typography>
                        <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                            <PhoneButton phoneNumber='+387 63 384 171' />
                        </Box>
                    </Box>

                    <Box mt={2}>
                        <Typography color={"#fff"} variant='subtitle2' fontWeight={600}>Niko Milić</Typography>
                        <Typography color={"#fff"} variant='subtitle2'>+43 664 501 258</Typography>
                        <Box mt={1} display={"flex"} flexDirection={"row"} gap={2}>
                            <PhoneButton phoneNumber='+43 664 501 258' />
                            {/* <WhatsAppPhoneButton phoneNumber='043664501258' /> */}
                        </Box>
                    </Box>

                    {/* </Box> */}
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                        color={(theme) => theme.palette.primary.light}
                        mb={1}
                        fontWeight={600}>
                        {t("footer.payment")}
                    </Typography>

                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}  width={"100%"}>
                        <Typography flexGrow={1} color={"#fff"} variant='subtitle2' fontWeight={600}>Bagarić Berislav</Typography>
                        <CopyButton text='Bagarić Berislav'/>
                    </Box>

                    <Typography mt={1} color={"#fff"} variant='caption'>IBAN</Typography>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}  width={"100%"}>
                        <Typography flexGrow={1} color={"#fff"} variant='subtitle2' fontWeight={600}>BA393382804002347584</Typography>
                        <CopyButton text='BA393382804002347584'/>
                    </Box>

                    <Typography mt={1} color={"#fff"} variant='caption'>SWIFT</Typography>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}  width={"100%"}>
                        <Typography flexGrow={1} color={"#fff"} variant='subtitle2' fontWeight={600}>UNCRBA22</Typography>
                        <CopyButton text='UNCRBA22'/>
                    </Box>

                </Box>

                <FooterAddress />
            </Box>
        </Grid >
    )
}

export default Footer;