import React from 'react';
import { Button, CardMedia, Grid, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import TourDateList from '../../tour-date/TourDateList';
import TourFourListItem from './TourFourListItem';
import TourService from '../../../../../services/TourService';

interface TourFourTabViewProps {
}

var service = new TourService();
const TourFourTabView: React.FC<TourFourTabViewProps> = ({ }) => {
    const { t } = useTranslation();

    const tour = service.tours.find(t => t.id === 4);

    if (tour === undefined) {
        return null;
    }

    return (
        <Grid>
            <Box
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/${8}.jpg)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
                mb={4}
                pl={0}
                pr={0}
                width={"100vw"}
                marginLeft={"calc(50% - 50vw)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}>
                <Grid
                    p={2}
                    pt={6}
                    pb={6}
                    container
                    justifyContent={"center"}
                    sx={{
                        backdropFilter: "blur(0px)",
                        backgroundColor: "#b9b9b9cf"
                    }}>
                    <Box maxWidth={400} width={"100%"}>
                        <TourFourListItem tour={tour} showDetails />
                    </Box>
                </Grid>
            </Box>

            <Box mt={6} display={"flex"} flexDirection={"column"} width={"100%"} alignItems={"center"} justifyContent={"center"}>
                <Typography variant='h5' mb={4}>{t("tour.reserveYourRide")}</Typography>
            </Box>
            <TourDateList tour={tour} />
        </Grid>
    )
}

export default TourFourTabView;