import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import Tour from '../../../../models/Tour';
import TourWeek from '../../../../models/TourWeek';
import { useTranslation } from 'react-i18next';

interface WhatsAppReserveButtonProps {
    tourWeek: TourWeek
    tour: Tour;
}

const WhatsAppReserveButton: React.FC<WhatsAppReserveButtonProps> = ({ tourWeek, tour }) => {
    const {t} = useTranslation();
    const text = `Tour ${tour.id}%0a${tour.nightCount} ${t("tour.nights")} / ${tour.rideDayCount} ${t("tour.daysRide")}%0a${t("tour.weekNumber")}: ${tourWeek.weekNumber}`;
    return (
        <ButtonBase 
        sx={{
            ":hover": {
                backgroundColor: (theme) => theme.palette.primary.light,
                color: "#fff"
            }
        }}
        onClick={() => window.open(`https://wa.me/38763284294?text=${text}`)}>
            <Box
                sx={{
                    boxShadow: (theme) => theme.shadows[8],
                }}
                p={1}
                borderRadius={1}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
            >
                <WhatsAppIcon sx={{ color: "#25D366" }} />
                <Typography variant='caption' ml={0.5} mt={0.3} fontWeight={600}>{t("tour.reserve")}</Typography>
            </Box>
        </ButtonBase>
    )
}

export default WhatsAppReserveButton;