import { Box } from '@mui/material';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Mousewheel, Pagination } from 'swiper/modules';
import Basic from '../components/Basic';
import City from '../components/City';
import Nature from '../components/Nature';
import TourInfo from '../components/TourInfo';
import Gallery from '../components/Gallery';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface AboutUsPageProps {
}

const AboutUsPage: React.FC<AboutUsPageProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("appBar.aboutUs")}</title>
                <meta name="description" content={t("aboutUs.basic.row2")} />
                <link rel="canonical" href="https://enduroremi.com/about-us/" />
                <meta property="og:title" content={t("appBar.aboutUs")} />
            </Helmet>

            <Box
                mt={-2}
                width={"100vw"}
                height={{ xs: "calc(100vh - 80px)", sm: "calc(100vh - 96px)" }}
                marginLeft={"calc(50% - 50vw)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}>
                <Swiper
                    direction={'vertical'}
                    slidesPerView={1}
                    spaceBetween={0}
                    mousewheel={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Mousewheel, Pagination]}
                    className="mySwiper"
                    style={{
                        width: "100%",
                        height: "100vh"
                    }}
                >
                    {/* <SwiperSlide style={{ width: "100%", maxWidth: "100%", height: "calc(100vw - 200px)", textAlign: "left" }}>
                    <Basic />
                </SwiperSlide > */}
                    <SwiperSlide style={{ width: "100%", maxWidth: "100%", height: "100vw", textAlign: "left" }}>
                        <City />
                    </SwiperSlide>
                    <SwiperSlide style={{ width: "100%", maxWidth: "100%", height: "100vw", textAlign: "left" }}>
                        <Nature />
                    </SwiperSlide>
                    <SwiperSlide style={{ width: "100%", maxWidth: "100%", height: "100vw", textAlign: "left" }}>
                        <TourInfo />
                    </SwiperSlide>
                    <SwiperSlide style={{ width: "100%", maxWidth: "100%", height: "100vw", textAlign: "left" }}>
                        <Gallery />
                    </SwiperSlide>
                </Swiper>
            </Box>
        </>
    )
}

export default AboutUsPage;