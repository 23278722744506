import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from './Container';
import ResponsiveAppBar from './AppBar';
import Footer from './Footer';


interface AboutUsContainerProps {
}

const AboutUsContainer: React.FC<AboutUsContainerProps> = ({ }) => {
    return (
        <>
            <ResponsiveAppBar />
            <Outlet />
        </>
    )
}

export default AboutUsContainer;