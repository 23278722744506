import React, { useEffect, useState } from 'react';
import ImageList from '../component/ImageList';
import Image from '../../../models/Image';
import GalleryService from '../../../services/GalleryService';
import { Box, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import YoutubeSlider from '../../home/components/youtube/YoutubeSlider';
import SpecialImageList from '../component/SpecialImageList';
import { Helmet } from 'react-helmet-async';

interface GalleryPageProps {
}

const GalleryPage: React.FC<GalleryPageProps> = ({ }) => {
    const [specialImages, setSpecialImages] = useState<string[]>([]);
    const [otherImages, setOtherImages] = useState<string[]>([]);
    const [roomImages, setRoomImages] = useState<string[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        var galleryService = new GalleryService();

        var specialImagesResponse = galleryService.specialImages;
        var otherImagesResponse = galleryService.otherImages;
        var roomImagesResponse = galleryService.roomImages;

        setSpecialImages(specialImagesResponse);
        setOtherImages(otherImagesResponse);
        setRoomImages(roomImagesResponse);

    }, [])

    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("appBar.gallery")}</title>
                <link rel="canonical" href="https://enduroremi.com/gallery/" />
                <meta property="og:title" content={t("appBar.gallery")} />
            </Helmet>

            <SpecialImageList images={specialImages} folder='gallery/full' />

            <Typography variant='h6' mt={4} fontWeight={600} mb={1}>{t("gallery.rooms")}</Typography>
            {/* <GalleryImageViewer images={images} /> */}
            <ImageList images={roomImages} folder='gallery/rooms' />


            <Typography variant='h6' mt={4} fontWeight={600} mb={1}>{t("gallery.other")}</Typography>
            {/* <GalleryImageViewer images={images} /> */}
            <ImageList images={otherImages} folder='gallery' />


            <br />
            <br />

            <Box mb={{ xs: -6, md: -10 }}>
                <YoutubeSlider />
            </Box>
        </Box>

    )
}

export default GalleryPage;