import React from 'react';
import { useTranslation } from 'react-i18next';
import TourIncludedItem from './TourIncludedItem';

interface IncludedMotorsProps {
}

const IncludedMotors: React.FC<IncludedMotorsProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <>
            <TourIncludedItem title={"GASGAS 2TAKT 2024 BJ"} />
            <TourIncludedItem title={"KTM 4TAKT 2017 BJ"} />
        </>
    )
}

export default IncludedMotors;