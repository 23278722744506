import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, ButtonBase, Paper, Typography } from '@mui/material';
import MonthTour from '../../../../models/MonthTour';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WhatsAppReserveButton from './WhatsAppReserveButton';
import Tour from '../../../../models/Tour';
import { useTranslation } from 'react-i18next';

interface TourDateListItemProps {
    monthTour: MonthTour;
    tour: Tour
}

const TourDateListItem: React.FC<TourDateListItemProps> = ({ monthTour, tour }) => {
    const {t} = useTranslation();
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography variant='h6' fontWeight={600}>{monthTour.month}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {monthTour.tourWeeks.map((tourWeek) => (
                    <ButtonBase
                        key={`${monthTour.month}/${tourWeek.weekNumber}`}
                        sx={{
                            p: 1.5,
                            mb: 1.5,
                            width: "100%",
                            boxShadow: (theme) => theme.shadows[8],
                            justifyContent: "flex-start",
                            "&:hover": {
                                bgcolor: "#f6f6f6"
                            }
                        }}>
                        <Box display={"flex"} flexDirection={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography variant='subtitle2' fontWeight={600}>
                                {`${t("tour.weekNumber")}: ${tourWeek.weekNumber}`}
                            </Typography>
                            <WhatsAppReserveButton tourWeek={tourWeek} tour={tour} />
                        </Box>
                    </ButtonBase>
                ))}
            </AccordionDetails>
        </Accordion>

        // <Box>
        //     <Typography mt={2} variant='h6' fontWeight={600}>{monthTour.month}</Typography>
        //     <Box>
        //         {monthTour.tours.map((tour) => (
        //             <Paper sx={{ p: 1.5, mt: 1.5, boxShadow: (theme) => theme.shadows[6] }}>
        //                 <Box>
        //                     <Typography variant='subtitle2' fontWeight={600}>
        //                         {`${tour.checkInDate} - ${tour.checkOutDate}`}
        //                     </Typography>
        //                 </Box>
        //             </Paper>
        //         ))}
        //     </Box>
        // </Box>
    )
}

export default TourDateListItem;