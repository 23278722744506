import React from 'react';
import { Outlet } from 'react-router-dom';
import Container from './Container';
import ResponsiveAppBar from './AppBar';
import Footer from './Footer';
import WhatsAppFixedButton from '../components/common/WhatsAppFixedButton';

interface LayoutProps {
}

const Layout: React.FC<LayoutProps> = ({ }) => {
    return (
        <>
            <ResponsiveAppBar />
            <Container>
                <Outlet />
            </Container>
            <WhatsAppFixedButton />
            <Footer />
        </>
    )
}

export default Layout;