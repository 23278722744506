import React from 'react';
import { Fab, Typography } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

interface WhatsAppFixedButtonProps {
}

const WhatsAppFixedButton: React.FC<WhatsAppFixedButtonProps> = ({ }) => {
    return (
        <Fab onClick={() => window.open(`https://wa.me/38763284294`)} color="secondary" aria-label="whatsapp" sx={{ position: "fixed", bottom: 16, right: 16 }}>
            <WhatsAppIcon sx={{ color: "#25D366" }} />
        </Fab>
    )
}

export default WhatsAppFixedButton;