import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import ToursTab from '../components/ToursTab';
import { useLocation } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface TourPageProps {
}

const TourPage: React.FC<TourPageProps> = ({ }) => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("appBar.offer")}</title>
                <meta name="description" content={t("tour.reserveYourRide")} />
                <link rel="canonical" href="https://enduroremi.com/tours/" />
                <meta property="og:title" content={t("appBar.offer")} />
            </Helmet>
            <ToursTab />
        </>
    )
}

export default TourPage;