import { createTheme, responsiveFontSizes } from "@mui/material";

let DefaultTheme = createTheme({
  palette: {
    primary: {
      main: '#df5c2d',
      light: "#E26C42"
    },
    secondary: {
      main: '#73653db8',
      dark: "#303e24",
      light: "#73653d10"
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          borderRadius: 12
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 12
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: 12
        }
      }
    }
  },
  shape: {
    borderRadius: 12
  },
  shadows: [
    "none",
    "rgba(9, 30, 66, 0.25) 0px 4px 6px -5px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
    "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
    "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
    "0 1px 5px rgb(0 0 0 / 15%)",
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.25px",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]
});

DefaultTheme = responsiveFontSizes(DefaultTheme);

export default DefaultTheme;