import SwipeDownAltIcon from '@mui/icons-material/SwipeDownAlt';
import { Zoom, useScrollTrigger } from '@mui/material';
import React from 'react';

interface SwipeDownIconProps {
}

const SwipeDownIcon: React.FC<SwipeDownIconProps> = ({ }) => {
    return (
        <HideOnScroll>
            <SwipeDownAltIcon
                fontSize='large'
                sx={{
                    position: "absolute",
                    marginLeft: "auto",
                    marginRight: "auto",
                    left: 0,
                    right: 0,
                    bottom: 16,
                    color: "#fff",
                    zIndex: 5
                }} />
        </HideOnScroll>
    )
}

export default SwipeDownIcon;

interface Props {
    children: React.ReactElement;
}


function HideOnScroll(props: Props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return (
        <Zoom appear={false} in={!trigger}>
            {children}
        </Zoom>
    );
}