import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DEFlag from '../../assets/flags/de.svg';
import ENFlag from '../../assets/flags/en.svg';
import HRFlag from '../../assets/flags/hr.svg';

const lngs = {
    de: { nativeName: 'Deutsch', flag: DEFlag },
    en: { nativeName: 'English', flag: ENFlag },
    hr: { nativeName: 'Croatian', flag: HRFlag },
}

interface LanguageSelectorProps {
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ }) => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { t, i18n } = useTranslation();

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOnLanguageChange = (lng: string) => {
        i18n.changeLanguage(lng);
        handleCloseUserMenu()
    }

    return (
        <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, }}>
                <img style={{  width: 36, borderRadius: 4 }} src={lngs[(i18n.resolvedLanguage ?? "en") as keyof typeof lngs].flag} />
            </IconButton>
            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {Object.keys(lngs).map((lng) => (
                    <MenuItem key={lng}
                        sx={{
                            backgroundColor: (theme) => i18n.resolvedLanguage === lng ? theme.palette.primary.light : "#fff"
                        }}
                        onClick={() => handleOnLanguageChange(lng)}>
                        <img style={{ width: 36, borderRadius: 4 }} src={lngs[lng as keyof typeof lngs].flag} />
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}

export default LanguageSelector;