import React, { useState } from 'react';
import { Button, Popover, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CopyButtonProps {
    text: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ text }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        navigator.clipboard.writeText(text);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'copy-popover' : undefined;
    return (
        <div>


            <Button
                onClick={handleClick}
                aria-describedby={id}
                sx={{
                    ml: 1.5,
                    bgcolor: "#414141",
                    borderRadius: 0.5,
                    padding: "2px 6px",
                    ":hover": {
                        bgcolor: "#757575",
                    },
                    ":focus": {
                        bgcolor: "#414141",
                    }
                }}>
                <Typography variant='caption' color={"#fff"}>{t("common.copy")}</Typography>
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: "0px 6px" }} variant='caption'>{t("common.copied")}</Typography>
            </Popover>

        </div>
    )
}

export default CopyButton;