import React, { useCallback, useEffect, useState } from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import MUIImageList from '@mui/material/ImageList';
import MUIImageListItem from '@mui/material/ImageListItem';
import ImageViewer from 'react-simple-image-viewer';
import Image from '../../../models/Image';

interface ImageListProps {
    images: string[];
    folder: string;
}

const ImageList: React.FC<ImageListProps> = ({ images, folder }) => {
    const [mappedImages, setMappedImages] = useState<string[]>([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);


    useEffect(() => {
        let mappedImages1: string[] = [];
        images.forEach(image => {
            var imageUrl = `${process.env.PUBLIC_URL}/images/${folder}/${image}.jpg`;
            mappedImages1.push(imageUrl);
        });
        setMappedImages(mappedImages1);
    }, [images])

    const openImageViewer = useCallback((index: number) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <>
            <Box
                display={"grid"}
                gap={0.5}
                sx={{
                    marginLeft: -1.5,
                    marginRight: -1.5,
                    gridTemplateColumns: { xs: "repeat(2, 1fr) !important", md: "repeat(3, 1fr) !important" }
                }}>
                {images.map((image, index) => (
                    <CardMedia
                        id="card-media"
                        key={`${folder}-${image}`}
                        component="img"
                        onClick={() => openImageViewer(index)}
                        loading='lazy'
                        image={`${process.env.PUBLIC_URL}/images/${folder}/${image}.jpg`}
                        alt={"1"}
                        sx={(theme) => ({
                            height: "100%",
                            minHeight: 200,
                            maxHeight: 200,
                            width: "100%",
                            // objectFit: "contain"
                            borderRadius: 0.5,
                            cursor: "pointer"
                        })}
                    />
                ))}
            </Box>

            {isViewerOpen && (
                <ImageViewer
                    src={mappedImages}
                    backgroundStyle={{ marginTop: 56 }}
                    currentIndex={currentImage}
                    disableScroll={false}
                    closeOnClickOutside={true}
                    onClose={closeImageViewer}
                />
            )}
        </>

    )
}

export default ImageList;

function srcset(path: string, size: number, rows = 1, cols = 1) {
    var imageUrl = `${process.env.PUBLIC_URL}/images/${path}.jpg`;
    return {
        src: `${imageUrl}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${imageUrl}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}