import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DirectionsIcon from '@mui/icons-material/Directions';
import GoogleMapsDialog from '../components/google/maps/components/GoogleMapsDialog';

interface FooterAddressProps {
}

const FooterAddress: React.FC<FooterAddressProps> = ({ }) => {
    const { t } = useTranslation();

    const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Typography
                color={(theme) => theme.palette.primary.light}
                mb={1}
                fontWeight={600}
                sx={{
                    // textDecoration: "underline",
                    // textDecorationColor: (theme) => theme.palette.primary.light
                }}>
                {t("footer.address")}
            </Typography>
            <Box>
                <Typography color={"#fff"} variant='subtitle2'>Paločki put</Typography>
                <Typography color={"#fff"} variant='subtitle2'>70280 Gornji Vakuf - Uskoplje</Typography>
                <Typography color={"#fff"} variant='subtitle2'>Bosna i Hercegovina</Typography>

                <Button
                    onClick={() => setIsMapDialogOpen(true)}
                    sx={{
                        mt: 2,
                        pt: 2,
                        pb: 2,
                        pl: 6,
                        pr: 6
                    }} variant='contained'>
                    <Box display={"flex"} flexDirection={"row"}>
                        <DirectionsIcon />
                        <Typography ml={1} fontWeight={600}>
                            {t("common.openMaps")}
                        </Typography>
                    </Box>
                </Button>
            </Box>
            <GoogleMapsDialog isOpen={isMapDialogOpen} onClose={() => setIsMapDialogOpen(false)} />
        </Box>
    )
}

export default FooterAddress;