import { Box, Button, ButtonBase, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tour from '../../../models/Tour';
import IncludedBasic from './common/IncludedBasic';

interface TourListItemSimpleProps {
    tour: Tour | undefined;
    showDetails?: boolean;
    children: React.ReactNode | React.ReactNode[]
}

const TourListItemSimple: React.FC<TourListItemSimpleProps> = ({ tour, children, showDetails = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (tour === undefined) {
        return null;
    }


    return (
        <ButtonBase
            onClick={() => navigate(`/tours?tourId=${tour.id}`)}
            sx={{
                width: "100%",
                // maxWidth: { md: 420 },
                // flex: "1 1 300px"
            }}>
            <Paper
                sx={{
                    width: "100%",
                    "&:hover": {
                        bgcolor: "#f6f6f6"
                    },
                }}>
                <Grid
                    p={2}
                    container
                    bgcolor={"primary.light"}
                    direction={"column"}
                    alignItems={"center"}
                    sx={{
                        borderTopLeftRadius: 12,
                        borderTopRightRadius: 12
                    }}>
                    <Typography
                        // fontWeight={600}
                        color={"#fff"}>
                        {`${tour.nightCount} ${t("tour.nights")} / ${tour.rideDayCount} ${t("tour.daysRide")}`}
                    </Typography>
                    <Box display={"flex"} flexDirection={"row"} mt={1.5} alignItems={"center"}>
                        <Typography variant='h4' color={"#fff"} fontWeight={600}>
                            €
                        </Typography>
                        <Typography variant='h2' ml={0.5} color={"#fff"} fontWeight={600}>
                            {`${tour.priceWithOwnMotorcycle}`}
                        </Typography>
                    </Box>

                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Typography color={"#fff"} fontWeight={600}>
                            €
                        </Typography>
                        <Typography variant='h4' ml={0.5} color={"#fff"} fontWeight={600}>
                            {`${tour.price}`}
                        </Typography>
                        <Typography ml={0.5} color={"#fff"}>
                            {`${t("tour.withOurMotorcycle")}`}
                        </Typography>
                    </Box>

                    {/* <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <Typography
                            variant='subtitle2'
                            color={"text.secondary"}
                            ml={0.5} >
                            {t("tour.withOwnMotorcycle")}
                        </Typography>
                    </Box> */}

                </Grid>
                <Grid p={2} pl={6} pr={6} container direction={"column"} alignItems={"flex-start"}>
                    <IncludedBasic tour={tour} />
                    {showDetails && children}
                </Grid>

                {!showDetails && <Button sx={{ width: "100%", p: 1 }} >{t("tour.details")}</Button>}
            </Paper >
        </ButtonBase>
    )
}

export default TourListItemSimple;