import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TourOneListItem from './tab-views/tour-one/TourOneListItem';
import TourThreeListItem from './tab-views/tour-three/TourThreeListItem';
import TourTwoListItem from './tab-views/tour-two/TourTwoListItem';
import TourFourListItem from './tab-views/tour-four/TourFourListItem';
import TourService from '../../../services/TourService';

interface TourListProps {
}

var service = new TourService();
const TourList: React.FC<TourListProps> = ({ }) => {
    const { t } = useTranslation();

    const tour1 = service.tours.find(t => t.id === 1);
    const tour2 = service.tours.find(t => t.id === 2);
    const tour3 = service.tours.find(t => t.id === 3);
    const tour4 = service.tours.find(t => t.id === 4);

    if (tour1 === undefined || tour2 === undefined || tour3 === undefined || tour4 === undefined) {
        return null;
    }

    return (
        <Grid
            sx={{

                backdropFilter: {xs: "blur(16px)", md: "none"},
                backgroundColor: "#ffffff63"
            }}
            pt={4}
            pb={6}
            container
            direction={"column"}
            alignItems={"center"}
            width={"100%"}>
            <Typography variant='h3'>{t("tour.title")}</Typography>
            <Box
                mt={2}
                display={"grid"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gridTemplateColumns={{ xs: "repeat(1, 1fr) !important", sm: "repeat(2, 1fr) !important", md: "repeat(2, 1fr) !important", lg: "repeat(4, 1fr) !important" }}
                p={2}
                gap={4}
                width={"100%"}>
                <TourOneListItem tour={tour1} />
                <TourTwoListItem tour={tour2} />
                <TourThreeListItem tour={tour3}/>
                <TourFourListItem tour={tour4}/>
            </Box>
        </Grid >
    )
}

export default TourList;