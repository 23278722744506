import React from 'react';
import { useTranslation } from 'react-i18next';
import TourIncludedItem from './TourIncludedItem';

interface ExtraOfferProps {
}

const ExtraOffer: React.FC<ExtraOfferProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <>
            <TourIncludedItem title={t("tour.localSpecialties")} displayIcon={false} />
            <TourIncludedItem title={t("tour.bbqInNature")} displayIcon={false} />
            <TourIncludedItem title={t("tour.dayTrips")} displayIcon={false} />
        </>
    )
}

export default ExtraOffer;