import type { RouteObject } from "react-router-dom";
import Layout from "../layout/Layout";
import HomePage from "../components/home/pages/HomePage";
import TourPage from "../components/tour/pages/TourPage";
import GalleryPage from "../components/gallery/pages/GalleryPage";
import AboutUsPage from "../components/about-us/pages/AboutUsPage";
import AboutUsContainer from "../layout/AboutUsContainer";

let Routes: RouteObject[] = [
    {
        // path: "/",
        // element: <LandingPage />,

        element: <Layout />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/tours",
                element: <TourPage />,
            },
            {
                path: "/gallery",
                element: <GalleryPage />,
            },
        ],
    },
    {

        element: <AboutUsContainer />,
        children: [
            {
                path: "/about-us",
                element: <AboutUsPage />,
            },
        ],
    },
];

export default Routes;
