
import DirectionsIcon from '@mui/icons-material/Directions';
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SwipeDownIcon from '../../common/SwipeDownIcon';
import GoogleMapsDialog from '../../google/maps/components/GoogleMapsDialog';

interface CityProps {
}

const City: React.FC<CityProps> = ({ }) => {
    const { t } = useTranslation();

    const [isMapDialogOpen, setIsMapDialogOpen] = useState(false);

    return (
        <Box
            sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/full/${3}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative",
                // zIndex: 3,
                "&::before": {
                    background: "#0e0e0e",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    content: '""',
                    opacity: .4
                }
            }}
            pl={0}
            pr={0}
            width={"100%"}
            height={"100vh"}
            // marginLeft={"calc(50% - 50vw)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid
                pt={6}
                pb={6}
                zIndex={10}
                container
                justifyContent={"flex-start"}>
                <Container maxWidth="xl">
                    <Typography color={"#fff"} variant='h1'>{t("aboutUs.city.title")}</Typography>
                    <Box mt={2} maxWidth={700}>
                        <Typography color={"#fff"} variant='h6'>{t("aboutUs.city.row1")}</Typography>
                        <Typography mt={0} color={"#fff"} variant='h6'>{t("aboutUs.city.row2")}</Typography>
                    </Box>

                    <Button
                        onClick={() => setIsMapDialogOpen(true)}
                        sx={{
                            mt: 2,
                            pt: 2,
                            pb: 2,
                            pl: 6,
                            pr: 6
                        }} variant='contained'>
                        <Box display={"flex"} flexDirection={"row"}>
                            <DirectionsIcon />
                            <Typography ml={1} fontWeight={600}>
                                {t("common.openMaps")}
                            </Typography>
                        </Box>
                    </Button>
                </Container>
            </Grid>
            <GoogleMapsDialog isOpen={isMapDialogOpen} onClose={() => setIsMapDialogOpen(false)} />
            <SwipeDownIcon />
        </Box>
    )
}

export default City;