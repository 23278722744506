import MonthTour from "../models/MonthTour";
import Tour from "../models/Tour";

export default class TourService {
    tourDates: MonthTour[] = [];

    getTourDates = async () => {
        var response = await fetch('data/tourDate.json');

        var tourDates = (await response.json()) as MonthTour[];
        return tourDates;
    }

    tours: Tour[] = [
        {
            id: 1,
            price: 580,
            priceWithOwnMotorcycle: 325,
            nightCount: 3,
            rideDayCount: 2
        },
        {
            id: 2,
            price: 750,
            priceWithOwnMotorcycle: 420,
            nightCount: 4,
            rideDayCount: 3
        },
        {
            id: 3,
            price: 860,
            priceWithOwnMotorcycle: 550,
            nightCount: 5,
            rideDayCount: 4
        },
        {
            id: 4,
            price: 1070,
            priceWithOwnMotorcycle: 700,
            nightCount: 6,
            rideDayCount: 5
        }
    ];
}