import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import TourService from '../../../../services/TourService';
import MonthTour from '../../../../models/MonthTour';
import { Box } from '@mui/system';
import TourDateListItem from './TourDateListItem';
import Tour from '../../../../models/Tour';

interface TourDateListProps {
    tour: Tour
}

const service = new TourService();
var response = service.getTourDates().then(r => r);

const TourDateList: React.FC<TourDateListProps> = ({ tour }) => {
    const [monthTours, setMonthTours] = useState<MonthTour[]>([]);

    useEffect(() => {
        async function fetchData() {
            // var response = await service.getTourDates();
            setMonthTours(await response);
        }

        fetchData();
    }, [])

    return (
        <Grid>
            {monthTours?.map((monthTour) => (
                <TourDateListItem key={monthTour.month} monthTour={monthTour} tour={tour} />
            ))}
        </Grid>
    )
}

export default TourDateList;