import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import PromoSlider from '../components/promo/PromoSlider';
import TourList from '../../tour/components/TourList';
import Landing from '../components/landing/Landing';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import YoutubeSlider from '../components/youtube/YoutubeSlider';
import { Helmet } from "react-helmet-async";
import GoogleReviews from '../../google/reviews/components/GoogleReviews';

interface HomePageProps {
}

const HomePage: React.FC<HomePageProps> = ({ }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t("appBar.home")}</title>
                <meta name="description" content={t("aboutUs.basic.row1")} />
                <link rel="canonical" href="https://enduroremi.com/" />
                <meta property="og:title" content={t("appBar.home")} />
            </Helmet>

            <Landing />
            <PromoSlider />
            <Box
                sx={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/gallery/full/${4}.jpg)`,
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                }}
                width={"100vw"}
                marginLeft={"calc(50% - 50vw)"}
                mt={{ xs: 2, md: 4 }}
                bgcolor={"aliceblue"}>
                <TourList />
            </Box>

            <Box mt={{ xs: 6, md: 10 }} mb={{ xs: 6, md: 10 }} display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                <Button
                    onClick={() => navigate("/about-us")}
                    sx={{
                        pt: 2,
                        pb: 2,
                        pl: 6,
                        pr: 6
                    }} variant='contained'>
                    <Typography fontWeight={600}>
                        {t("home.moreAboutUs")}
                    </Typography>
                </Button>
            </Box>

            <Box mb={{ xs: -6, md: -10 }}>
                <YoutubeSlider />
            </Box>

            {/* <GoogleReviews/> */}
        </>
    )
}

export default HomePage;