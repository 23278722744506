import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface PhoneButtonProps {
    phoneNumber: string;
}

const PhoneButton: React.FC<PhoneButtonProps> = ({ phoneNumber }) => {
    const { t } = useTranslation();
    return (
        <ButtonBase onClick={() => window.open(`tel:${phoneNumber}`)}>
            <Box
                sx={{
                    boxShadow: "#ffffff0d 0px 0px 0px 1.2px"
                }}
                p={1.5}
                borderRadius={1}
                // width={80}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <LocalPhoneIcon sx={{ color: "#fff" }} />
                {/* <Typography color={"#fff"} variant='caption' mt={0.5}>{t("footer.call")}</Typography> */}
            </Box>
        </ButtonBase>
    )
}

export default PhoneButton;