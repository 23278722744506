import React from 'react';
import Tour from '../../../../../models/Tour';
import TourListItemSimple from '../../TourListItemSimple';
import TourItemDescription from '../../common/TourItemDescription';

interface TourTwoListItemProps {
    tour: Tour;
    showDetails?: boolean;
}

const TourTwoListItem: React.FC<TourTwoListItemProps> = ({ tour, showDetails = false }) => {
    return (
        <TourListItemSimple tour={tour} showDetails={showDetails}>
            <TourItemDescription tour={tour} />
        </TourListItemSimple>
    )
}

export default TourTwoListItem;