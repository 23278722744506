import React from "react";
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTubeIcon from '@mui/icons-material/YouTube';

import 'swiper/css';
import 'swiper/css/pagination';

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import YoutubeItem from "./YoutubeItem";
import { useNavigate } from "react-router-dom";

interface YoutubeSliderProps {
}

const YoutubeSlider: React.FC<YoutubeSliderProps> = ({ }) => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                position: "relative",
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/${1}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                zIndex: 3,
                "&::before": {
                    backdropFilter: "blur(66px)",
                    backgroundColor: "#ffffff87",
                    // background: "#575757",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    content: '""',
                    // opacity: .8
                }
            }}
            // mb={4}
            pl={0}
            pr={0}
            width={"100vw"}
            marginLeft={"calc(50% - 50vw)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid
                pt={6}
                pb={6}
                zIndex={10}
                container
                justifyContent={"flex-start"}>
                <Container maxWidth="lg">
                    <Box
                        p={{ xs: 1, md: 2 }} mr={-2} ml={-2}>
                        <Box display={"grid"} gap={{ xs: 1, md: 2 }}
                            sx={{
                                gridTemplateColumns: { xs: "repeat(1, 1fr) !important", md: "repeat(2, 1fr) !important" }
                            }}>
                            <YoutubeItem embed="nrNU7BiEtu8" />
                            <YoutubeItem embed="Njre95XVuik" />
                            <YoutubeItem embed="ReV2IdLqbZc" />
                            <YoutubeItem embed="mvBKW4DCfF0" />
                        </Box>
                    </Box>
                    <Box mt={{ xs: 4, md: 4 }} mb={{ xs: 0, md: 0 }} display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
                        <Button
                            onClick={() => window.open("https://www.youtube.com/@enduroremi861")}
                            sx={{
                                pt: 2,
                                pb: 2,
                                pl: 6,
                                pr: 6,
                                backgroundColor: "#282828"
                            }} variant='contained'>
                            <Box display={"flex"} flexDirection={"row"}>
                                <YouTubeIcon sx={{ color: "#FF0000" }} />
                                <Typography ml={1} fontWeight={600}>
                                    Youtube
                                </Typography>
                            </Box>
                        </Button>
                    </Box>

                </Container>
            </Grid>

        </Box>
    )
}

export default YoutubeSlider;