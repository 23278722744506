import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CardMedia, Container, ListItemIcon, useScrollTrigger } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CollectionsIcon from '@mui/icons-material/Collections';
import InfoIcon from '@mui/icons-material/Info';
import LanguageSelector from '../components/common/LanguageSelector';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'About', 'Contact'];

export default function DrawerAppBar(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

            <CardMedia
                id="card-media"
                component="img"
                image={`${process.env.PUBLIC_URL}/images/logo/logo.jpg`}
                alt={"1"}
                sx={(theme) => ({
                    // height: 180,
                    width: "100%",
                    objectFit: "contain"
                    // borderRadius: 1,
                })}
            />

            <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
                Enduro Remi
            </Typography>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'start' }} onClick={() => navigate("/")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }} >
                            <HomeIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t("appBar.home")} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton sx={{ textAlign: 'start' }} onClick={() => navigate("/tours?tourId=1")}>
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }} >
                            <LocalOfferIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t("appBar.offer")} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/gallery")}>
                    <ListItemButton sx={{ textAlign: 'start' }}>
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }} >
                            <CollectionsIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t("appBar.gallery")} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding onClick={() => navigate("/about-us")}>
                    <ListItemButton sx={{ textAlign: 'start' }}>
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }} >
                            <InfoIcon color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={t("appBar.aboutUs")} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <CssBaseline />
            <ElevationScroll pathName={pathname}>
                <AppBar component="nav" >
                    <Container maxWidth='xl'>
                        <Toolbar sx={{ pl: { xs: 0, lg: 0 }, pr: { xs: 0, lg: 0 } }}>
                            <Box
                                onClick={() => navigate("/")}
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={"flex-start"}
                                sx={{ flexGrow: 1 }}>
                                <CardMedia
                                    id="card-media"
                                    component="img"
                                    image={`${process.env.PUBLIC_URL}/images/logo/logo-transparent.png`}
                                    alt={"1"}
                                    sx={(theme) => ({
                                        height: 50,
                                        width: 50,
                                        // width: "100%",
                                        objectFit: "contain",
                                        borderRadius: 0.5,
                                        cursor: "pointer"
                                    })}
                                />
                                {/* <Typography
                                    onClick={() => navigate("/")}
                                    variant="h6"
                                    component="div"
                                >
                                    Enduro-Remi
                                </Typography> */}
                            </Box>

                            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <LanguageSelector />
                            </Box>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ ml: 1, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>

                            <Box sx={{ display: { xs: 'none', sm: 'flex' } }} gap={1}>
                                <Button sx={{ color: '#fff' }} onClick={() => navigate("/")}>
                                    {t("appBar.home")}
                                </Button>
                                <Button sx={{ color: '#fff' }} onClick={() => navigate("/tours?tourId=1")}>
                                    {t("appBar.offer")}
                                </Button>
                                <Button sx={{ color: '#fff' }} onClick={() => navigate("/gallery")}>
                                    {t("appBar.gallery")}
                                </Button>
                                <Button sx={{ color: '#fff' }} onClick={() => navigate("/about-us")}>
                                    {t("appBar.aboutUs")}
                                </Button>
                            </Box>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }} ml={1.5}>
                                <LanguageSelector />
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
            <nav>
                <Drawer
                    anchor='right'
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </nav>
            <Box component="main">
                <Toolbar />
            </Box>
        </>

        // </Box>
    );
}

interface ElevationScrollProps {
    children: React.ReactElement;
    pathName: string;
}

function ElevationScroll(props: ElevationScrollProps) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    let style = {};

    if (props.pathName === "/" || props.pathName === "/about-us") {
        style = {
            backgroundColor: trigger ? "#df5c2d" : "transparent"
        }
    }

    return React.cloneElement(children, {
        elevation: trigger ? 1 : 0,
        style: style
    });
}