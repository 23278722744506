import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';

interface WhatsAppPhoneButtonProps {
    phoneNumber: string;
}

const WhatsAppPhoneButton: React.FC<WhatsAppPhoneButtonProps> = ({ phoneNumber }) => {
    return (
        <ButtonBase onClick={() => window.open(`https://wa.me/${phoneNumber}`)}>
            <Box
                sx={{
                    boxShadow: "#ffffff0d 0px 0px 0px 1.2px"
                }}
                p={1.5}
                borderRadius={1}
                display={"flex"}
                // width={80}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <WhatsAppIcon sx={{ color: "#25D366" }} />
                {/* <Typography color={"#fff"} variant='caption' mt={0.5}>WhatsApp</Typography> */}
            </Box>
        </ButtonBase>
    )
}

export default WhatsAppPhoneButton;