import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeDownIcon from '../../common/SwipeDownIcon';
interface TourInfoProps {
}

const TourInfo: React.FC<TourInfoProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/${9}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative",
                // zIndex: 3,
                "&::before": {
                    background: "#0e0e0e",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    content: '""',
                    opacity: .4
                }
            }}
            pl={0}
            pr={0}
            width={"100%"}
            height={"100vh"}
            // marginLeft={"calc(50% - 50vw)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid
                pt={6}
                pb={6}
                zIndex={10}
                container
                justifyContent={"flex-start"}>
                <Container maxWidth="xl">
                    <Typography color={"#fff"} variant='h1'>{t("aboutUs.tourInfo.title")}</Typography>
                    <Box mt={4} maxWidth={700}>
                        <Typography color={"#fff"} variant='h6'>{t("aboutUs.tourInfo.row1")}</Typography>
                        <Typography mt={2} color={"#fff"} variant='h6'>{t("aboutUs.tourInfo.row2")}</Typography>
                    </Box>

                </Container>
            </Grid>
            <SwipeDownIcon />
        </Box>
    )
}

export default TourInfo;