import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SwipeDownIcon from '../../../common/SwipeDownIcon';
import SocialMediaList from '../../../common/SocialMediaList';


interface LandingProps {
}

const Landing: React.FC<LandingProps> = ({ }) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                position: "relative",
                backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/${10}.jpg)`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                marginTop: { xs: "-72px", sm: "-80px" },
                zIndex: 3,
                "&::before": {
                    // background: "#575757",
                    background: "#3f3f3f",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    content: '""',
                    // opacity: .4,
                    opacity: .6
                }
            }}
            mb={4}
            pl={0}
            pr={0}
            width={"100vw"}
            height={"100vh"}
            marginLeft={"calc(50% - 50vw)"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}>
            <Grid
                pt={6}
                pb={6}
                zIndex={10}
                container
                justifyContent={"flex-start"}>
                <Container maxWidth="xl">
                    <Typography color={"#fff"} variant='h1'>{t("aboutUs.basic.title")}</Typography>
                    <Box mt={4} mb={2} maxWidth={600}>
                        <Typography color={"#fff"} variant='h6'>{t("aboutUs.basic.row1")}</Typography>
                        <Typography mt={2} color={"#fff"} variant='h6'>{t("aboutUs.basic.row2")}</Typography>
                    </Box>

                    <SocialMediaList />
                </Container>

            </Grid>
            <SwipeDownIcon />
        </Box>
    )
}

export default Landing;