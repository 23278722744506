import React from 'react';
import { useTranslation } from 'react-i18next';
import Tour from '../../../../../models/Tour';
import TourListItemSimple from '../../TourListItemSimple';
import TourItemDescription from '../../common/TourItemDescription';

interface TourOneListItemProps {
    tour: Tour,
    showDetails?: boolean;
}

const TourOneListItem: React.FC<TourOneListItemProps> = ({ tour, showDetails = false }) => {
    const { t } = useTranslation();

    return (
        <TourListItemSimple tour={tour} showDetails={showDetails}>
            <TourItemDescription tour={tour}/>
        </TourListItemSimple>

        // <TourListItem
        //     index={0}
        //     title={t("tour.tour1.title")}
        //     fromTo={t("tour.tour1.from-to")}
        //     price={t("tour.tour1.price")}
        //     priceWithOwnMotorcycle={t("tour.tour1.priceWithOwnMotorcycle")}>
        //     <>
        //         <TourIncludedItem title={t("tour.tour1.include.item1")} />
        //         <TourIncludedItem title={t("tour.tour1.include.item2")} />
        //         <TourIncludedItem title={t("tour.tour1.include.item3")} />
        //         <TourIncludedItem title={t("tour.tour1.include.item4")} />
        //         <TourIncludedItem title={t("tour.tour1.include.item5")} />
        //     </>
        // </TourListItem>
    )
}

export default TourOneListItem;