import React from 'react';
import logo from './logo.svg';
import './App.css';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router-dom';
import Routes from './shared/Routes';
import DefaultTheme from './themes/DefaultTheme';
import { ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';

const lngs = {
  hr: { nativeName: 'Croatian' },
  en: { nativeName: 'English' },
  de: { nativeName: 'Deutsch' }
}
const helmetContext = {}

function App() {
  const { t, i18n } = useTranslation();
  var element = useRoutes(Routes)

  return (
    <HelmetProvider  context={helmetContext}>
      <ThemeProvider theme={DefaultTheme}>
        {element}
      </ThemeProvider>
    </HelmetProvider>
    // <div>
    //   {Object.keys(lngs).map((lng) => (
    //     <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
    //       {lngs[lng as keyof typeof lngs].nativeName}
    //     </button>
    //   ))}
    // </div>
  );
}

export default App;
