
import React, { useEffect } from "react"
import { Box, CardMedia, Grid, Paper, Typography } from "@mui/material";

interface PromoItemProps {
    title: string,
    subTitle: string,
    imageName: string,
    backgroundColor: string
}

const PromoItem = ({ title, subTitle, imageName, backgroundColor = "#fff" }: PromoItemProps) => {
    return (
        <Paper
            sx={{
                mb: 5,
                width: "100%",
                position: "relative",
                borderRadius: 1,
                backgroundColor: backgroundColor,
                boxShadow: (theme) => theme.shadows[5]
            }}>
            <Grid wrap="nowrap" container direction="column" alignItems="flex-start">
                <Box overflow={"hidden"} borderRadius={1} width={"100%"}>
                    <CardMedia
                        id="card-media"
                        component="img"
                        image={`${process.env.PUBLIC_URL}/images/home-slider/${imageName}.jpg`}
                        alt={"1"}
                        sx={(theme) => ({
                            height: 260,
                            width: "100%",
                            [theme.breakpoints.up("sm")]: {
                                height: 290
                            },
                            borderRadius: 1,
                            transition: "transform .2s",
                        })}
                    />
                </Box>
                <Grid
                    sx={{
                        borderBottomLeftRadius: 8,
                        borderBottomRightRadius: 8,
                        backdropFilter: "blur(6px)"
                    }}
                    width={"100%"}
                    p={1}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="start"
                    position={"absolute"}
                    bottom={0}
                    bgcolor={"#e26c42a1"}>
                    <Typography variant="subtitle2" color={"#fff"}>
                        {title}
                    </Typography>
                    {/* <Typography ml={0.2} variant="subtitle2" fontWeight={600} color={"black"}>
                        {subTitle}
                    </Typography> */}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default PromoItem;