import { Container as MUIContainer } from '@mui/material';
import React from 'react';

interface ContainerProps {
    children: React.ReactNode
}

const Container: React.FC<ContainerProps> = ({ children }) => {
    return (
        <MUIContainer sx={{ pt: 2, pb: 2 }} maxWidth={'lg'}>
            {children}
        </MUIContainer>
    )
}

export default Container;