import React from 'react';
import { Typography } from '@mui/material';

interface CustomMapProps {
}

const CustomMap: React.FC<CustomMapProps> = ({ }) => {
    return (
        <>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3416.700512649217!2d17.575221555968007!3d43.93678590480771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475f0cb13c21b489%3A0x38fe1db91296b939!2sEnduro%20Remi!5e0!3m2!1sen!2sba!4v1707820494385!5m2!1sen!2sba"
                width="100%"
                height="100%"
                style={{ border: 0, borderRadius: 8 }}
                loading="lazy" ></iframe>
        </>
    )
}

export default CustomMap;