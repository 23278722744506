import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TourOneTabView from './tab-views/tour-one/TourOneTabView';
import TourTwoTabView from './tab-views/tour-two/TourTwoTabView';
import TourThreeTabView from './tab-views/tour-three/TourThreeTabView';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { useLocation } from 'react-router';
import TourFourTabView from './tab-views/tour-four/TourFourTabView';
import { useSearchParams } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index &&
                children
            }
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ToursTab = () => {
    const [tourId, setTourId] = React.useState(0);
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSearchParams({ ["tourId"]: `${newValue + 1}` })
        setTourId(newValue);
    };



    let query = useQuery();
    React.useEffect(() => {
        var tourId = query.get("tourId");
        if (tourId !== undefined && tourId !== null && (tourId === "1" || tourId === "2" || tourId === "3" || tourId === "4")) {
            setTourId(Number(tourId) - 1);
        }
    }, []);

    return (
        <Box sx={{ width: '100%' }}>
            <Box mb={4} ml={-1} mr={-1} sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    centered
                    value={tourId}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant='fullWidth'>
                    <StyledTab label={t("tour.tour1")} {...a11yProps(0)} />
                    <StyledTab label={t("tour.tour2")} {...a11yProps(1)} />
                    <StyledTab label={t("tour.tour3")} {...a11yProps(2)} />
                    <StyledTab label={t("tour.tour4")} {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tourId} index={0}>
                <TourOneTabView />
            </CustomTabPanel>
            <CustomTabPanel value={tourId} index={1}>
                <TourTwoTabView />
            </CustomTabPanel>
            <CustomTabPanel value={tourId} index={2}>
                <TourThreeTabView />
            </CustomTabPanel>
            <CustomTabPanel value={tourId} index={3}>
                <TourFourTabView />
            </CustomTabPanel>
        </Box>
    );
}

export default ToursTab;


interface StyledTabProps {
    label: string;
}


const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
        backgroundColor: '#1890ff',
    },
});

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        marginRight: theme.spacing(1),
        fontWeight: 600,
        fontSize: 16,
        margin: 8,
        [theme.breakpoints.down('sm')]: {
            margin: 4,
        },
        // marginLeft: 16,
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1.25px",
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            opacity: 1,
        },
        '&.Mui-selected': {
            color: "#fff",
            backgroundColor: theme.palette.primary.light,
            fontWeight: 600
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);